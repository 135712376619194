
/*
 * Put all your regular jQuery in here.
*/

jQuery(document).ready(function($) {

  //###########################################################################
  //  Mega menu
  //###########################################################################
  
  var megaMenuRaw = $('.mega-menu-corporate').first().find('> ul').clone();
  $('.mega_menu_wrap').html('');
  megaMenuRaw.find('.site-menu').each(function(){
    var siteMenuClass = $(this).attr('class').match(/site-menu--\w+/gm);
    var siteMenuWrap = $('<div class="site-menu-wrap"><div class="site-img"></div></div>');
    var siteMenuContainer = $('<ul></ul>');
    var newUl = $(this).appendTo(siteMenuContainer);
    var newMenuWrap = siteMenuWrap.append(newUl);
    newMenuWrap.addClass(siteMenuClass[0]);
    newMenuWrap.appendTo('.mega_menu_wrap');
  });

  $('.mega_menu ul').css('display', 'block');

  
  $('body').on('mouseenter', '.mega-menu-corporate', function (e) {
    $('.mega_menu').slideDown('fast');
  });

  var megaMenuTimer1;

  $(".mega-menu-corporate, .mega_menu").mouseleave(function () {
    megaMenuTimer1 = setTimeout(function(){
      $('.mega_menu').slideUp('fast');
    }, 50);
  }).mouseenter(function () {
    clearTimeout(megaMenuTimer1);
  });

  $(window).on("click", function (event) {
    if ($('.mega-menu-corporate').has(event.target).length == 0 && !$('.mega-menu-corporate').is(event.target)) {
      $('.mega_menu').removeClass('show');
    }
  });

  //###########################################################################
  //  Conditions of Sale
  //###########################################################################

  $('.conditions_of_sale__selection select').on('select2:select', function(e) {
    const selectionValue = this.value;
    const selectionDocument = $(this).find('option:selected').data('document');

    if(selectionDocument === undefined) return;

    const pageUrl = window.location.href.split('?')[0];
    const documentUrl = pageUrl + '?selection=' + selectionValue + '&document=' + selectionDocument;

    $.get(
      documentUrl, 
      {}, 
      function(response){
        if($(response).find('.conditions_of_sale__document__content').length) {
          var documentHtml = $(response).find('.conditions_of_sale__document');
          $('.conditions_of_sale__document').html(documentHtml);

          var target = $('#conditions_of_sale__document');
          $('html, body').animate({
            scrollTop: target.offset().top - 80
          }, 500);
        }
      }
    );

    window.history.pushState({}, '', documentUrl);
  })

}); /* end of as page load scripts */
